import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M28.683 0.258997V35H22.607V20.349L22.8275 14.567L21.088 17.213L14.375 25.102L7.662 17.213L5.9225 14.567L6.192 20.349V35H0.116V0.258997H0.606L14.424 16.5515L28.144 0.258997H28.683Z"
          fill="currentColor"
          style={{ transform: 'translate(24px, 26px)' }}
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
